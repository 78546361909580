<template>
  <div class="home-page"></div>
</template>

<script>
export default {

}
</script>

<style scoped>
.home-page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: url("../../public/img/big-1.png") no-repeat center center;
}
</style>